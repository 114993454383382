<template>
  <div>
    <template v-if="$route.meta.allowAnonymous == true">
      <Header></Header>
      <Navbar></Navbar>
      <main>
        <router-view />
        <div class="sidebar sidAudioCall">
          <div class="sidebar-heading">
            <span
              class="bi-x-lg close-sidebar"
              @click="closeAudioSidebar()"
            ></span>
            <h4>Web Phone</h4>
          </div>
          <Dialer></Dialer>
        </div>
        <div class="sidebar sidVideoCall">
          <div class="sidebar-heading">
            <span
              class="bi-x-lg close-sidebar"
              @click="closeVideoSidebar()"
            ></span>
            <h4>Video Call</h4>
          </div>

          <Consultation></Consultation>
          <div
            class="videoZoom"
            title="Show Full Screen"
            @click="fullScreenVideo()"
          >
            <span class="bi-arrow-left-circle-fill"></span>
          </div>
        </div>
      </main>
      <Footer></Footer>
    </template>
    <template v-if="!$route.meta.allowAnonymous">
      <router-view />
    </template>
  </div>
</template>

<script>
import $ from "jquery";
//Bootstrap 5
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
// Dailer CSS
import "../public/dialer/css/phone.css";
// Custom CSS
import "./assets/css/style.css";
// Responsive CSS
import "./assets/css/responsive.css";
// bootstrap icons
import "./assets/css/bootstrap-icons.min.css";

import Header from "./views/include/Header.vue";
import Footer from "./views/include/Footer.vue";
import Navbar from "./views/include/Navbar.vue";
import Consultation from "./views/Consultation.vue";
import Dialer from "./views/include/Dialer.vue";
import { computed, reactive } from "vue";
import { useHead } from "@vueuse/head";

export default {
  components: {
    Navbar,
    Header,
    Footer,
    Consultation,
    Dialer,
  },
  name: "App",
  setup() {
    const siteData = reactive({
      type: "website11",
      // url : 'https://awrxhub.com/enrollment11',
      title: `Enrollment - Las Vegas College Medical Clinic`,
      description: `About Us Welcome to Las Vegas College Medical Clinic, your trusted partner in the pursuit of optimal health and wellness11`,
      // image : 'https://amazinglywellrx.com/wp-content/uploads/elementor/thumbs/ser1-q9avgantq6mxas6u7v4ajscftxaz1sbbovhu3v5bq8.jpg11',
      site_name: "Las Vegas College Medical Clinic",
    });
    useHead({
      title: computed(() => siteData.title),
      meta: [
        {
          property: `og:type`,
          content: computed(() => siteData.type),
        },
        {
          property: `og:url`,
          content: computed(() => siteData.url),
        },
        {
          property: `og:title`,
          content: computed(() => siteData.title),
        },
        {
          property: `og:description`,
          content: computed(() => siteData.description),
        },
        {
          property: `og:image`,
          content: computed(() => siteData.image),
        },
      ],
    });
  },
  methods: {
    closeAudioSidebar() {
      $(".sidAudioCall").toggleClass("sidebarDailerActive");
    },
    closeVideoSidebar() {
      $(".sidVideoCall").toggleClass("sidebarVideoActive");
    },
    fullScreenVideo() {
      $(".sidVideoCall").toggleClass("sidebarVideoFull");
      $(".bi-arrow-left-circle-fill").toggleClass("bi-arrow-right-circle-fill");
    },
  },
};
</script>
