<template>
  <footer>
    <!-- {{footer_year}} -->
    <p>©2022 — Las Vegas College Medical Clinic. All Rights Reserved.</p>
  </footer>
</template>

<script>
export default {
  name: "Footer",

  data() {
    return {
      footer_year: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" scoped></style>
